@import "../utils/mixins.scss";
@import "../utils/functions.scss";
@import "../utils/responsive.scss";
@import "colors.scss";

.summernoteContent {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1rem;
    color: color('primary');

    @include respond-below(mobile) {
        text-align: center;
    }

    img {
        max-width: 100%;
    }

    h1 {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 600;
        font-size: 2rem !important;
        line-height: 2.5rem;
    }

    h2 {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem !important;
        line-height: 1.6rem;
    }

    h3 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 0.8rem !important;
        line-height: 1rem;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    p,
    ol,
    ul,
    pre,
    blockquote,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
}
