.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: attr(data-value);
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: attr(data-value);
}

/* Set default font-family */
.app .ql-editor {
    font-family: "Lato";
}

/* Set dropdown font-families */
.ql-font span[data-label="Lato"]::before,
.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
    font-family: "Lato" !important;
}

.ql-font .ql-font span[data-label="Lora"]::before,
.ql-picker.ql-font .ql-picker-label[data-value="Lora"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lora"]::before {
    font-family: "Lora" !important;
}

.ql-font span[data-label="Poppins"]::before,
.ql-picker.ql-font .ql-picker-label[data-value="Poppins"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Poppins"]::before {
    font-family: "Poppins" !important;
}

.ql-font span[data-label="Arial"]::before,
.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
    font-family: "Arial" !important;
}

/* Set content font-families */
.ql-font-Lato {
    font-family: "Lato" !important;
}

.ql-font-Lora {
    font-family: "Lora" !important;
}

.ql-font-Poppins {
    font-family: "Poppins" !important;
}

.ql-font-Arial {
    font-family: "Arial" !important;
}