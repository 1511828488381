@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.houseButtonContainer {
    margin-top: 5rem;
    text-align: center;

    .houseButton {
        margin-top: 3.125rem;
    }

    @include respond-below(mobile) {
        margin-top: 0;
    }
}
