@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.container{

}

.buttonsContainer {    
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2rem;
    @include respond-below(xs) {
        justify-content: center;
    }
}

.paddingLeft {
    margin-left: 10px;
}

.photoCoverLoad {
    padding-top: 1.9rem;

    @include respond-below(sm) {
        width: 50%;
        padding-top: 1rem;
        margin: auto;
    }
    
    @include respond-below(xs) {
        width: 100%;
    }
}

.photoLoad {
    width: 100%;
    height: 8rem;
    margin-bottom: 1rem;

    @include respond-below(sm) {
        width: 50%;
        padding-top: 1rem;
        margin: auto;
        margin-bottom: 1rem;
    }
    
    @include respond-below(xs) {
        width: 100%;
    }
}

.previewContainer {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    border: 3px solid transparent;
    padding: 3px;

    @include respond-below(sm) {
        .previewOverlay {
            display: flex;
        }
    }

    @include respond-above(sm) {
        &:hover .previewOverlay {
            display: flex;
        }
    }
}

.previewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
}

.previewOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.8);
    align-items: center;
    justify-content: center;
    display: none;
}

.deleteButton {
    background-color: #fff;
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
}

.textRight {
    text-align: right;
}

.sectionTitle {
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

