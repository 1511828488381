@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    position: relative;    
}

.innerContainer {
    position: relative;    
}

.titleWrapper {
    display: flex;
}

.titleContainer {
    width: 100%;
    height: 900px;    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
    text-align: center;
    //margin-top: 3.8rem;
    
    .title {
        font-weight: 700;
        font-size: 6rem;
        line-height: 6.2rem;
        margin-bottom: 2rem;
        color: color('basicWhite');
        text-transform: uppercase;
        @include respond-below(mobile) {
            font-size: 2.6rem;
            line-height: 3rem;
        }
    }

    .subTitle {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        @include respond-below(mobile) {
            font-size: 1rem;
            line-height: 1.4rem;
        }
        color: color('basicWhite');
    }
}

.headerButtonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4.4rem;

    @include respond-below(mobile) {
        flex-direction: column;
        margin-top: 2.4rem;

        button {
            margin-bottom: 1rem;
        }
    }
}