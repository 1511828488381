@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {    
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
}

.menuContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.info {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navLinks {
  color: color("primary");
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;    
  padding: 1rem 0.5rem;
  width: 100%;

  &.title{
    background-color: color('secondary');
    color: color('basicGrayDarker');
    font-size: 1.75rem;
  }

  .description {
    width: 100%;
  }

  &.active{
    color: color('primary');    
  }

  .coloredSelected{
    color: color('primary');
    font-weight: bold;
  }
}

.itemContainer {
  cursor: pointer;

  .menuDescription
  {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;      
  }
}

.circularIcon {
  width: 2.75rem;
  height: 2.75rem;  
  cursor: pointer;
  border-radius: 2rem;
}


