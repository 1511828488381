@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.advantagesContainer {
    margin-top: 10.875rem;
    margin-bottom: 15rem;

    @include respond-below(mobile) {
        margin-top: 5.625rem;
        margin-bottom: 10rem;
    }
}

.advantageGridLayout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 10rem;
    margin-top: 3.125rem;

    @include respond-below(mobile) {
        display: flex;
        flex-direction: column;
    }

    .advantageItem {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .advantageImage {
        max-width: 100%;
        width: 100%;
        background-size: cover;
        height: auto;
        max-height: 50rem;
    }

    .advantageDescriptionContainer {
        width: 70%;
        height: 160px;
        background-color: #f2f2f2;
        position: absolute;
        bottom: -90px;
        padding: 10px 20px;
        display: table;

        .advantageTitle {
            font-family: "Lato", sans-serif;
            font-size: 24px;
            font-weight: 500;
            text-align: center;
            color: black;
            height: 80px;
            display: table-cell;
            vertical-align: middle;

            w {
                font-family: 'Lora';
                font-style: italic;
                font-weight: 500;
            }
        }

        .advantageDescription {
            font-family: "Lato", sans-serif;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            margin-top: 10px;
            height: 80px;

            display: table-cell;
            vertical-align: middle;
        }

        .separator {
            margin-top: 0;
            margin-bottom: 0;
            width: 30%;
        }
    }
}


.advantageItem:nth-child(3n+1) .separator {
    border-color:color('darkOrange');
}

.advantageItem:nth-child(3n + 2) .separator {
    border-color:color('greyGreen');
}

.advantageItem:nth-child(3n) .separator {
    border-color:color('greyOrange');
}