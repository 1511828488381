@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";


.container {
    background-color: color('basicWhite');
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.contentContainer {
    flex: 1;
    // overflow: auto;
}

.overflowCon{
    overflow: hidden;
}