@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";

.input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid color('primary');
    color: color('primary');
    padding-bottom: 5px;
    font-family: 'Lato', sans-serif;
}

.textArea {
    resize: none;
}

.input::placeholder{
    font-family: 'Lato',sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
}

.input:focus {
    outline: none;
}

.formItem {
    display: block;
    margin-bottom: 20px;
}

.flexProperties {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}