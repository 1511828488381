@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    position: relative;
    overflow: hidden;
}

.imageCoverUrl {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    filter: brightness(70%);
    background-size: cover;
}

.area3d {
    position: absolute;
    left: 5%;
    top: 10%;
    width: 100%;
    text-align: center;
    color: #000;
}

.button3d {
    background-color: rgb(255, 255, 255);
    padding: 0.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 4.5rem;
    height: 4.5rem;
}

.areaImageGallery {
    position: absolute;
    width: 16rem;
    height: 8rem;
    border: solid;
    border-width: 0.1rem;
    border-color: #eee;
    cursor: pointer;
    bottom: 2rem;
    right: 4rem;

    @include respond-below(mobile) {
        width: 14rem;
        height: 6rem;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
    }
}

.imageGallery {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2%;
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    box-shadow: 0 0 0.3rem 0.3rem #eee;
    filter: blur(1.5px) brightness(95%);
}

.textGallery {
    position: absolute;
    color: white;
    font-size: 1.8rem;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-below(mobile) {
        font-size: 1.4rem;
        padding: 0.4rem;
    }
}

.mobileHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem 1rem;

    .mobileHeaderCol {
        flex: 1;
    }

    .mobileHeaderIcon {
        background-color: rgb(255, 255, 255);
        padding: 0.6rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 3rem;
        height: 3rem;
    }
}
