@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.overflow {
    overflow: hidden;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: color("basicWhite");
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 4;

    @include respond-below(mobile) {
        width: 100%;
    }

    .button {
        position: absolute;
        left: 20px;

        svg {
            width: 25px;
            height: 25px;
        }
    }
}

.innerContainer {
    width: 100%;
    margin-top: 3rem;
    padding: 1rem 2.625rem 2.625rem 2.625rem;
    overflow-y: auto;
}

.logoContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}



.item {
    margin-bottom: 3rem;

    .text {
        text-align: center;

        font-family: "Lora", serif;
        font-size: 24px;
        // font-weight: 600;
        line-height: 31px;
        letter-spacing: 0px;
        color: color("primary");
        margin-bottom: 1rem;

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bold{
        div {
         font-weight: bold !important;
        }
    }
    

    hr {
        border-color: color("primary");
    }

    a {
        text-decoration: none;
    }

    .chevron {
        position: absolute;
        right: 0;

        width: 20px;
        height: 20px;
    }

    .openChevron {
        transform: scale(-1, -1);
    }
}

.circularIcon {
    width: 2.75rem;
    height: 2.75rem;
    cursor: pointer;
    border-radius: 2rem;
}

.navLinks {
    color: color('primary');
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    padding: 1rem 0.5rem;
    width: 100%;
}
