@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/theme/colors.scss";

.button {  
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    padding: 0.9rem 1.5rem;
    cursor: pointer;
}
