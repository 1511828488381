@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    padding: 2rem 0;
    padding-bottom: 1rem;

    @include respond-below(mobile) {
        padding: 2rem 1.4rem;
    }
    &.containerDark {
        background-color: color('basicGrayDarker');
        border-top: 1px solid color('borderBlack');
    }
}

.information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: color('basicWhite');
    margin-bottom: 6rem;

    @include respond-below(mobile) {
        flex-direction: column;
        margin-bottom: 2rem;
 
    }

    a {
        color: color('basicWhite');
        font-weight: 600;
    }

    .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-right: 1.8rem;

        @include respond-below(mobile) {
            padding-right: 0;
            margin-bottom: 3rem;
            align-items: center;
            text-align: center;
        }

        &:last-child {
            padding-right: 0;

            @include respond-below(mobile) {
                margin-bottom: 0;
            }
        }
    }

    .logoContainer {
        flex: none;
        margin-right: 3rem;

        .socialsContainer{
            margin-top:1rem;
            display: flex;
            justify-content: space-between;
        }

        @include respond-below(mobile) {
            justify-content: center;
            align-items: center;
            margin-right: 0;
        }
    }

    .label {
        font-family: 'Lato';
        font-weight: 900;
        font-size: 0.9rem;
        line-height: 1.25rem;
        text-transform: uppercase;
        margin-bottom: 0.9rem;

        &.labelDark {
            color: color('selectorDark');
        }
    }

    .text {
        font-family: 'Poppins';
        font-size: 0.9rem;
        line-height: 1.375rem;
    }

    .link {
        font-size: 0.9rem;
        line-height: 1.375rem;
        text-decoration: none;
        margin-bottom: 1rem;
        font-family: 'Poppins';
        font-weight: 500;
    }
}

.bottom {
    color: color('basicWhite');
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include respond-below(mobile) {
        flex-direction: column-reverse;
    }

    .copyrightText {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 0.7rem;
        line-height: 1.125rem;
        flex: 1;
        color: color('basicWhite');
        text-align: center;
        
        &.copyrightTextDark {
            color: color('selectorDark');
        }

        @include respond-below(mobile) {
            margin-top: 3rem;
        }
    }
}
