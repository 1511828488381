@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    top: 0;
    z-index: 3;  
    position: absolute;
    width: 100%;
    transition: 1s all ease;
}

.menuIcon {
    left: 0;
    position: absolute;
    padding-left: 20px;
}

.scrolledDownContainer{
    position: sticky !important;
    background-color: white;
    
    transition: 1s all ease;
}

.blackIcon {
    svg {
        path {
            stroke: black
        }  
    }
}
.whiteContainer {
    background-color: white;
}

.logoContainer {
    text-align: center;
    padding: 2rem 0 0 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.stickyContainer {
    padding: 1rem 0;
}

.itemsContainer {
     display: flex;
     flex-direction: row;
     align-items: center;
     width: 100%;
     justify-content: center; 
    // @include respond-below(lg) {
    //     --app-height: 100vh;
    //     display: inline-block;
    //     flex-direction: column;
    //     align-items: flex-start;
    //     justify-content: center;        
    //     background-color: color("white");
    //     overflow-y: auto;        
    //     border-top: 1px solid color("athensGray");
    //     &.isOpen {
    //         display: block;
    //     }
    // }
}