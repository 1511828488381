@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/theme/colors.scss";

.link {
    color: color("lightblack");
    padding: 1.2rem 1rem;
    display: flex;
    flex-direction: column;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    
    .title {        
        display: flex;
        align-items: center;
        flex-direction: row;       
        text-transform: uppercase;

        &.minWidth {
            min-width: 10rem;
        }

        &.dark {
            color: color("basicBlack");
        }

        &.light {
            color: color("basicWhite");
        }
    }

    &:hover,
    &.active {
        font-weight: bold;
    }
}
