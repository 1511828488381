@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/functions.scss";
@import "../../styles/utils/responsive.scss";
@import "../../styles/theme/colors.scss";

.container {
    background-color: color('basicWhite');
    position: relative;
}

// Because of the lines we need this inner container
.innerContainer {
    z-index: 5;
    position: relative;
    overflow: hidden; 
}

.headerButtonIcon {
    margin-right: 0.6rem;
}


.bodyContainer {
    margin-top: 10.875rem;

    @include respond-below(mobile) {
        margin-top: 5.625rem;
    }
}

.sectionTitle {
    font-family: 'Lora', serif;
    font-size: 42px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0px;
    color: color('primary');

    @include respond-below(mobile){
        text-align: center;
     }
}

.sectionDescription {
    font-family: 'Lora', serif;
    font-size: 18px;
    font-weight: 400;
    text-align: justify;

}

.homePartenersImg{
    background-size: cover;
    width: 100%;
}

.homePartner{
    width: 100%;
    height: 900px;
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
    text-align: center;

    @include respond-below(mobile){
        height: 600px;
    }

    .partnerText {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 64px;
        line-height: 96px;
        text-align: center;
        color: white;

        w {
            font-family: 'Lora', serif;
        }

        @include respond-below(mobile){
            font-size: 2.5rem;
            line-height: 3rem;
        }
    }

    .partnerButtonDiv{
        margin-top: 8rem;

        .partnerButton {
            padding-right:60px;
            padding-left:60px;
        }
    }
    
}

.home_contact {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 5rem;

    .contactButton {
        margin-top: 3.125rem;
    }

    .contactText {
        font-family: 'Lato', sans-serif;
        font-size: 64px;
        font-weight: 600;
        text-align: center;
        color: color('basicGrayDarker');
        position: relative;

        w {
            font-family: 'Lora';
            font-style: italic;
            font-weight: 600;
        }

        @include respond-below(mobile){
            font-size: 40px;
        }
    }

    .contactText:before {
        content: "";
        display: block;
        width: 60px;
        height: 5px;
        background: color('basicGrayDarker');
        left: -60px;
        top: 35%;
        position: absolute;

        @include respond-below(mobile){
            top: 50%;
            width: 20px;
            left: -20px;
        }
      }

      .contactText:after {
        content: "";
        display: block;
        width: 60px;
        height: 5px;
        background: color('basicGrayDarker');
        right: -60px;
        bottom: 10%;
        position: absolute;

        @include respond-below(mobile){
            bottom: 50%;
            width: 20px;
            right: -20px;
        }
      }
}