@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {    
    padding: 1.5rem 10rem;
    display: flex;
    flex-direction: row;

    @include respond-below(mobile) {
        padding: 1.5rem 2.4rem;
        flex-direction: column;
    }

    .containerLeft {
        flex: 1;
    }

    .containerRight {
        min-width: 380px;
        max-width: 100%;
        margin-left: 1rem;
    
        @include respond-below(lg) {
            min-width: 300px;
        }
    
        @include respond-below(mobile) {
            min-width: auto;
            width: 100%;
            margin-left: 0;
        }
    }
}

.title {
    margin-top: 1rem;
}

.description {    
    text-transform: uppercase;   
    font-family: 'Lora', serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    color: color('primary');
    margin-bottom: 0.6rem;

    @include respond-below(mobile) {
        text-align: center;
    }
}

.locationContainer {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

    @include respond-below(mobile) {
        justify-content: center;
        margin-bottom: 1.4rem;
        margin-top: 0.8rem;
    }
    
    .icon { 
        margin-right: 15px;
    }
}

.descriptionWithPraticalInformation {
    display: flex;
    flex-direction: row;

    @include respond-below(mobile) {
        flex-direction: column;
    }

    .descriptionCol {
        flex: 1;
    }

    .praticalInformationCol {
        min-width: 380px;
        max-width: 100%;
        margin-left: 1rem;

        @include respond-below(lg) {
            min-width: 300px;
        }

        @include respond-below(mobile) {
            min-width: auto;
            width: 100%;
            margin-left: 0;
        }
    }
}
