@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.webImage {
    max-width: 100%;
    background-size: cover;
    height: auto;
    max-height:40rem;

    @include respond-below(mobile){
        margin-top: 3rem;
    }
}

.itemContainer{
    display: flex;
    flex-direction: row;
    margin-top: 3.125rem;
    color: color('basicGrayDarker');

    @include respond-below(mobile){
        flex-direction: column;
    }

    .itemText{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .titleContainer{
            margin-bottom:2.5rem;

            .itemTitle{
                font-family: 'Lora', serif;
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                text-align: center;
            }
    
            .itemHR {
                margin-top:0;
                margin-bottom:0;
                width: 5rem;
            }
        }
         
        .itemDescription{
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            text-align: justify;
            line-height: 1.1rem;
            color: color('primary');
        }

    }

    .itemEven{
        padding-left: 6rem;

        @include respond-below(mobile){
            padding-left: 0;
        }
    }

    .itemOdd{
        padding-right: 6rem;

        @include respond-below(mobile){
            padding-right: 0;
        }
    }

    .itemButton{
        margin-top: 2rem;
    }
}


.itemContainer:nth-child(3n+1) .itemHR {
    border-color:color('darkOrange');
}

.itemContainer:nth-child(3n + 2) .itemHR {
    border-color:color('greyGreen');
}

.itemContainer:nth-child(3n) .itemHR {
    border-color:color('greyOrange');
}