@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.container{

}

.buttonsContainer {    
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2rem;
    @include respond-below(xs) {
        justify-content: center;
    }
}

.paddingLeft {
    margin-left: 10px;
}
