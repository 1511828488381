@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {    
    padding: 0rem 10rem;   

    @include respond-below(mobile){
        padding: 0rem 2.4rem; 
    }
}

.details {
    img {    
        max-width: 100%;
    }
}

.buttonContainer {
    text-align: center;
    margin-top: 3.125rem;

    .button{
        @include respond-below(mobile){
            width: 100%;
        }
    }
}