
body {
    font-family: 'Lato', sans-serif;
    height: 100%;
    overflow-x: hidden;
}

html {
    box-sizing: border-box;
    height: 100%;
}

#root {
    height: 100%;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

strong, b {
    font-weight: bold;
}
