@import "styles/utils/functions.scss";
@import "styles/utils/mixins.scss";
@import "styles/utils/responsive.scss";

.container{
    padding: 1rem;
}

.columnPlate {
    @include fontWeight('bold');
    font-size: 1.125rem;
}

.columnBrand,
.columnSpec {
    font-size: 0.875rem;
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}



.buttonsContainer {    
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    @include respond-below(xs) {
        justify-content: center;
    }
}
