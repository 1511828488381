@import "../../styles/utils/functions.scss";
@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/responsive.scss";

$bottomContainerHeight: 80%;
$middleContainerPadding: 5rem;

.container {
    width: 100vw;
    height:100vh;
    color: color('primaryLight');
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
        padding: 2.5rem 5rem;
        background: white;   
        box-shadow: 0px 0px 11px #00000029;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 35rem;
    }
}

.recoverPasswordLink {
    color: color('primaryDark');
    align-self: flex-end;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 15px;
}

.loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .alignRight {
        align-self: flex-end;
    }

    .button {
        margin-top: 2rem;
    }

    .loginTitle {
        font-size: 1.5rem;
        text-align: center;
        font-weight: unset;
        width: 100%;
    }

    .formItem {
        width: 100%;
    }
}

.recoverPasswordForm {
    margin-top: 3rem;

    .recoverPasswordTitle {
        font-size: 2.3rem;
        text-align: center;
        letter-spacing: 0px;
        color: #000000;
        margin: 0;
    }

    .recoverPasswordSubtitle {
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0px;
        color: #000000;
        margin: 0;
    }

    .recoverPasswordButtonContainer {
        display: flex;
        margin-top: 2rem;
        justify-content: flex-end;
    }
}

.setPasswordForm {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .setPasswordTitle {
        font-size: 2.3rem;
        text-align: center;
        letter-spacing: 0px;
        color: #000000;
        margin: 0;
    }

    .setPasswordSubtitle {
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0px;
        color: #000000;
        margin: 0;
    }

    .setPasswordButtonContainer {
        display: flex;
        margin-top: 2rem;
        justify-content: flex-end;
    }

    .formItem {
        width: 100%;
    }

    .alignRight {
        align-self: flex-end;
    }
}

.marginTop2 {
    margin-top: 2rem;
}


.buttonsContainer {    
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    @include respond-below(xs) {
        justify-content: center;
    }
}

.paddingLeft {
    margin-left: 10px;
}