@import "../../../styles/theme/colors.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";

.container {
    @include respond-below(mobile) {
        padding: 0;
    }
}

.sendBlueContainer{
    margin-bottom: 7rem;
    margin-top: 5rem;
    color: #ffff;
    height: 22.5rem;
    background-size: cover;   
    width: 100%;
    padding: 40px 12rem ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond-below(mobile){
        padding: 3rem 2.5rem;
    }
}

.textContainer{
    display: flex;
    flex-direction: column;  
    text-align: center;
    margin-top: 2rem;

    @include respond-below(mobile) {
        margin-top: 0;
    }

    .title{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 2.6rem;
        line-height: 43px;

        w {
            font-family: 'Lora';
            font-style: italic;
            font-weight: 500;
        }

        @include respond-below(mobile){
            font-size: 36px;
        }
    }
    
    .description{
        margin-top: 0.1rem;
        font-family: 'Lato', sans-serif;
        font-size: 0.9rem;

        @include respond-below(mobile){
            font-size: 0.9rem;
        }
    }
}

.form {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.inputBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // margin: 0 12rem;
    padding-bottom: 2rem;
    width: 50%;

    @include respond-below(lg) {
        width: 100%;
    }

    .webInput {
        width: 100%;
        border: 1px solid color('mercury');
        display: flex;
        align-items: center;
        background-color: color('basicWhite');
        height: 34px;

        @include respond-below(sm) {
            width: 100%;
        }

        input {
            border: none;
            width: 100%;
            height: 100%;
            margin-left: 1rem;
            text-overflow: ellipsis;
            overflow: hidden;

            &:focus {
                outline: none;
            }
        }

        input::placeholder{
            font-family: 'Lato',sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0px;
            margin-left: 1rem;
        }
    }
}

.inputButton{
    background-color: color('greyGreen');
    color: color('basicWhite');
    border: 2px solid transparent;
    display: flex;
    padding: 0.4rem 1rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.icon{
    margin-right: 0.5rem;
}
