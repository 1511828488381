@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/theme/colors.scss";
@import "../../../styles/utils/responsive.scss";

.timeline {
    padding-bottom: 3rem;
    padding-right: 5rem;
    padding-left: 5rem;
    overflow: hidden;

    @include respond-below(mobile) {
        padding-bottom: 2.4rem;
        padding-right: 1rem;
        padding-left: 1rem;
    }
     
     li {
        list-style: none;
        float: left;
        width: 20%;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color:#3E3A38; 
        font-family: 'Lora';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 20px;

        &.complete {
            color: #BB774A;

            &:before {                                
                border: 3px solid #BB774A;   
                background-color: #BB774A;
            }

            &:after {               
                background-color: #BB774A;
            }
        }

        &:before {                
            content: "";
            width: 10px;
            height: 10px;
            border: 3px solid #3E3A38;
            border-radius: 50%;
            display: block;                
            line-height: 25px;
            margin: 0 auto 10px auto;
            background: #3E3A38;                    
            color: #BB774A;
        }

        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: #3E3A38;
            top: 4px;
            left: 0;
            z-index: 0;                
        }
    }
}
