@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/functions.scss";
@import "../../styles/utils/responsive.scss";
@import "../../styles/theme/colors.scss";

.container {
    background-color: color("basicWhite");
    position: relative;
}

// Because of the lines we need this inner container
.innerContainer {
    z-index: 1;
    position: relative;
    overflow: hidden;
}

.headerButtonIcon {
    margin-right: 0.6rem;
}

.contactsContainer {
    margin-top: 2rem;
    margin-bottom: 20rem;
    position: relative;

    @include respond-below(mobile){
        text-align: center;
        margin-bottom: 0;
    }

    .flexRow {
        display: flex;
        justify-content: space-around;
        position: relative;
        z-index: 2;
    }

    .contactsListContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 6rem;

        @include respond-below(mobile){
            padding-top: 1rem;
        }
    }

    .contactItem {
        margin-bottom: 3rem;

        &:last-child {
            @include respond-below(mobile) {
                margin-bottom: 0;
            }
        }

        .contactIcon{
            padding-right:0.8rem
        }
    }

    .imageContainer {
        position: absolute;
        bottom: -210px;
        width:100%;

        @include respond-below(lg){
            bottom: -60px;
        }

        @include respond-below(xl){
            bottom: -100px;
        }

        .img {
            width: 100%;
        }
    }

}
.contactForm {
    border-radius: 0px;
    background-color: color('contactsFormGrey');
    padding: 40px 50px 15px 50px;
    width: 477px;
    color: color('primary');
    display: flex;
    flex-direction: column;

    .title{
        font-family: 'Lora', serif;
        font-size: 36px;
        font-style: italic;
        font-weight: 500;
        line-height: 46px;
        letter-spacing: 0px;

        @include respond-below(mobile) {
            text-align: center;
            margin-bottom: 0.4rem;
        }
    }

    .description {
        font-family: 'Lato', sans-serif;
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.1rem;
        letter-spacing: 0px;
        
        @include respond-below(mobile) {
            text-align: center;
        }
    }

    form{
        margin-top: 40px;
    }

    @include respond-below(mobile){
        width: 100%;
        margin-bottom:3.125rem;
    }
}

.sendingBlueMobileForm{
    margin-top:5rem;
}

.widthFull {
    width: 100%;
    padding-bottom:10px;
}

.messageInput {
    width: 100%;
    margin-bottom:10px;
}
.marginNone {
    margin: 0
}

.buttonRow {
    display: flex;
    justify-content: flex-end;

    @include respond-below(mobile) {
        justify-content: center;
    }
}

.sendButton {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
}
