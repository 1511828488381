@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/responsive.scss";

.container {
    display: flex;
    flex-direction: row;
    flex:1;
    padding: 2rem; 
    
    .leftMenu {
      display: flex;
      flex-direction: column;
      flex: 0.23;
      min-width: 15rem;
      padding: 0rem 5rem 0rem 0rem;      
    }

    .innerContainer {
        display: flex;
        flex-direction: column;
        flex:1;

        //padding: 2rem 5rem 2rem 0rem; 
      }    
}
