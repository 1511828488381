@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/functions.scss";
@import "../../styles/theme/colors.scss";
@import "../../styles/utils/responsive.scss";

.container {
    background-color: color('basicWhite');
    position: relative;
}

// Because of the lines we need this inner container
.innerContainer {
    z-index: 1;
    position: relative;
    overflow: hidden;
}

.headerButtonIcon {
    margin-right: 0.6rem;
}

.houseContainer{
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;

    @include respond-below(mobile){
        margin-top: 5rem;
        margin-bottom: 3.125rem;
    }

    .houseTypeContainer {
        margin-top: 3.375rem;
    }
}

.housesListingMarginTop{
    margin-top: 3rem;
}

.dividerContainer {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2.4rem;
}
