@import "../../styles/utils/mixins.scss";
@import "../../styles/utils/functions.scss";
@import "../../styles/utils/responsive.scss";
@import "../../styles/theme/colors.scss";

.container {
    background-color: color('basicWhite');
    position: relative;
}

// Because of the lines we need this inner container
.innerContainer {
    z-index: 5;
    position: relative;
    overflow: hidden; 
}

.headerButtonIcon {
    margin-right: 0.6rem;
}
