@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.container {
    position: relative;    
}

.innerContainer {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.titleWrapper {
    display: flex;
}

.titleContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;  
    text-align: center;

    @include respond-below(mobile) {
        padding: 0.6rem;
    }
    
    .title {
        font-family: 'Lato', sans-serif;
        font-weight: 600;
        font-size: 6rem;
        line-height: 7.6rem;
        margin-bottom: 2rem;
        color: color('basicWhite');
        text-transform: uppercase;

        w {
            font-family: 'Lora', serif;
            font-weight: 500;
            font-style: italic;
        }

        @include respond-below(xl) {
            font-size: 4.4rem;
            line-height: 5rem;
        }

        @include respond-below(mobile) {
            font-size: 40px;
            line-height: 51px;
        }
    }

    .subTitle {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        color: color('basicWhite');

        @include respond-below(mobile) {
            line-height: 1.4rem;
            font-size: 0.9rem;
            letter-spacing: 1px;
        }
    }
}

.headerButtonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 3rem;

    button {
        width: 100%;
        padding: 0.9rem 3rem;
    }

    @include respond-below(mobile) {
        flex-direction: column;
        width: 100%;
        padding: 0 3rem;
        bottom: 2rem;

        button {
            width: 100%;
            padding: 0.9rem 1.5rem;
        }
    }
}

