@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/theme/colors.scss";
@import "../../../styles/utils/responsive.scss";

.container {      
    padding: 1rem 1rem; 
    display: flex;
    flex-direction: column;    
    height: 290px;
    background: #FFFFFF;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.1);

    @include respond-below(mobile) {
        margin-top: 2.4rem;
        margin-bottom: 0.8rem;
        height: auto;
    }

    .title {
        font-family: 'Lora';
        font-weight: 500;        
        line-height: 20px;
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        color: color('primary');

        @include respond-below(mobile) {
            text-align: center;
        }
    }

    .price {       
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        display: flex;       
        color: #3E3A38;
        margin-top: 30px;

        @include respond-below(mobile) {
            justify-content: center;
        }

        .priceValue {
            font-family: 'Lato';
            font-weight: 700;
            font-size: 2rem;
        }
        .priceLabel {
            margin-left: 10px;
        }
    }

    .typologyContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        justify-content: space-evenly;
        background: #F5F5F5;
        padding: 1rem 1rem; 
        margin-top: 25px;
        width: 100%;
        height: 40px;

        .typologyItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 10px;

            .typologyIcon {                
                width: 20px;                
            }

            .typologyValue {          
                display: flex;      
                margin-left: 10px;
                font-size: 0.8rem;
                
                @include respond-below(mobile) {
                    font-family: 'Lato';
                    font-weight: 500;
                    font-size: 1rem;
                }
            }
        }
    }

    .buttonContainer {
        display: flex;
        flex-direction: column;
        flex:1;
        padding: 1rem 1rem; 
        margin-top: 1.8rem;

        @include respond-below(mobile) {
            margin-top: 1rem;
            padding-bottom: 0.5rem;
        }
    }

    .button {
        text-transform: uppercase;
    }
}
