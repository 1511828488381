@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.housesTitleDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .sectionTitle {
        font-family: 'Lora', serif;
        font-size: 32px;
        font-weight: 600;
        line-height: 41px;
        letter-spacing: 0px;
        color: color('primary');
    
        @include respond-below(mobile){
            text-align: center;
         }
    }

    @include respond-below(mobile) {
        display: flex;
        flex-direction: column;
    }

    .housesSubTitle {
        font-family: "Lato", sans-serif;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1rem;
        letter-spacing: 0px;
        text-align: left;
        max-width: 50%;
        color: color('primary');

        @include respond-below(mobile) {
            margin-top: 20px;
            text-align: center;
            max-width: 100%;
        }
    }
}

.housesGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    
    margin-top: 3rem;
    font-family: "Lato", sans-serif;

    @include respond-below(mobile) {
        display: flex;
        flex-direction: column;
    }

    .houseTitle {
        font-family: "Lora", serif;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;
        margin-top: 20px;
        color: color('primary');
    }

    .houseItem {
        cursor: pointer;
        @include respond-below(mobile) {
            margin-bottom: 2.125rem;
        }
    }
    .houseImageContainer {
        position: relative;
        aspect-ratio: 1 / 1;
      
        .houseImage {
            width: 100%;
            height: 100%;
            //object-fit: cover;
            object-position: center;
        }
    }

    .housePriceContainer {
        position: absolute;
        top: 20px;
        left: 20px;
        padding: 8px 16px;
        color: color("basicWhite");
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 0.9rem;
    }

    .houseItem:nth-child(3n+1) .housePriceContainer {
        background-color: color("greyGreen");
    }

    .houseItem:nth-child(3n+2) .housePriceContainer {
        background-color: color("greyOrange");
    }

    .houseItem:nth-child(3n) .housePriceContainer {
        background-color: color("darkOrange");
    }

    .markerIcon {
        width: 30px;
        height: 30px;
        margin-right: 13px;
    }

    .icon {
        width: 21px;        
    }

    .houseLocationContainer {
        display: flex;
        align-items: center;
        margin-top: 1.2rem;

        @include respond-below(mobile) {
            justify-content: center;
            margin-top: 0;
        }
    }

    .houseLocation {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1rem;
        letter-spacing: 0px;
        text-align: center;
        font-family: 'Lato';
    }

    .houseTypologies {
        display: flex;
        margin-top: 30px;
        flex-wrap: wrap;

        @include respond-below(mobile) {
            justify-content: center;
        }

        .typologieItem {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 20px;

            .typologieValue {
                font-size: 19px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: 0px;
                margin-left: 10px;
            }
        }
    }
}

.houseButtonContainer {
    margin-top: 5rem;
    text-align: center;
}

.houseInfoContainer {
    padding-left: 10px;

    @include respond-below(mobile){
        padding-left:0
    }
}
