@import "../../../styles/utils/mixins.scss";
@import "../../../styles/utils/functions.scss";
@import "../../../styles/utils/responsive.scss";
@import "../../../styles/theme/colors.scss";

.conceptContainer {
    margin-top: 10.875rem;

    @include respond-below(mobile){
        margin-top:  5.625rem;
     }
}

.conceptGridLayout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 7rem;
    grid-row-gap: 1.875rem;
    margin-top: 3.125rem;

    @include respond-below(mobile){
       display: flex;
       flex-direction: column;
       margin-top: 1.8rem;
    }

    .conceptImage{
        max-width: 100%;
        width: 100%;
        background-size: cover;
        height: auto;
        max-height:40rem;
    }


    .conceptDescription {
        padding: 30px 0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.1rem;
        text-align: justify;

        @include respond-below(mobile){
            line-height: 1.2rem;
        }
    }
}

.conceptButtonContainer {
    text-align: center;
    margin-top:3.125rem;
}